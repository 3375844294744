import { useContext, useRef, useState } from "react"
import { ChatContext } from "../context/ChatContext"
import { UserContext } from "../context/UserContext"
import React from "react";
import Webcam from "react-webcam";
import { useRecordWebcam } from "react-record-webcam";

const OPTIONS = {
    fileName: "test-filename",
    mimeType: "video/x-matroska;codecs=avc1",
    width: 600,
    height: 600,
    disableLogs: true
  } as const;

export const ChatInput: React.FC<{conversation_id:number,disabled:boolean}> = ({conversation_id,disabled}) => {
    const [message,setMessage] = useState("")
    const { sendMessage,targetUserId } = useContext(ChatContext)
    const { sendFile, sendFileCamera, sendFileCameraVideo } = useContext(ChatContext)
    const { user } = useContext(UserContext)
    // const { roomId } = useContext(RoomContext)
    const webcamRef = useRef<Webcam>(null);
    const [imgSrc, setImgSrc] = useState<string | null>(null);
    const [showCamera, setCamera] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showCameraRecord, setCameraRecord] = useState(false)

    const recordWebcam = useRecordWebcam(OPTIONS);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getRecordingFile = async () => {
        const blob = recordWebcam.getRecording();
        // console.log({ blob });
        getBlob(await blob)
    };
    
    const getBlob = async (blob: Blob | null) => {
        const videoSrc = {
            name: Date.now() + '.mkv',
            data: blob
        }
        sendFileCameraVideo(message,targetUserId,user.username,conversation_id,videoSrc)
        setCameraRecord(false)
    };

    const capture = React.useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                const imageCamera = {
                    name: Date.now() + '.webp',
                    data: imageSrc
                }
                setImgSrc(imageSrc);
                setCamera(false);
                sendFileCamera(message,targetUserId,user.username,conversation_id,imageCamera)
                setImgSrc("")
            }
        }
    }, [conversation_id, message, sendFileCamera, targetUserId, user.username, webcamRef]);
    
    return (
        <div>
            <form onSubmit={(e)=>{
                e.preventDefault()
                if(message.trim().length > 0){
                    sendMessage(message,targetUserId,user.username,conversation_id,File)
                    setMessage("")
                }  
            }}>
                            
                <div className="flex h-[80px] bottom-0 w-[80%] md:w-[80%] border p-2 fixed bg-white input-chat">
                {/* <div>
                    {
                        (showCameraRecord === true) ? (
                            <div className="container-camera-record bg-white">
                                <div className="flex justify-between items-center">
                                    <p className="text-center text-xs font-medium">{recordWebcam.status}</p> */}
                                    {/* onClick={recordWebcam.close} */}
                                    {/* <button
                                        disabled={recordWebcam.status === "CLOSED"}
                                        onClick={() => { setCameraRecord(false); recordWebcam.close();}}
                                        className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-10 h-10 button-primary"}
                                        type="button"
                                    > */}
                                        {/* Close camera */}
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg w-4 h-4" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                        </svg>
                                    </button>
                                </div>
                                <video
                                    height={600} width={600}
                                    ref={recordWebcam.webcamRef}
                                    style={{
                                        display: `${
                                        recordWebcam.status === "OPEN" ||
                                        recordWebcam.status === "RECORDING"
                                            ? "block"
                                            : "none"
                                        }`
                                    }}
                                    autoPlay
                                    muted
                                />
                                <video
                                    height={600} width={600}
                                    ref={recordWebcam.previewRef}
                                    style={{
                                        display: `${recordWebcam.status === "PREVIEW" ? "block" : "none"}`
                                    }}
                                    autoPlay
                                    muted
                                    loop
                                />
                            <div className="flex justify-center items-center">
                                <button
                                    disabled={
                                    recordWebcam.status === "CLOSED" ||
                                    recordWebcam.status === "RECORDING" ||
                                    recordWebcam.status === "PREVIEW"
                                    }
                                    onClick={recordWebcam.start}
                                    type="button"
                                    className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-10 h-10 button-primary"}
                                    > */}
                                    {/* Start recording */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-record-circle w-4 h-4" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                    </svg>
                                </button>
                                <button
                                    disabled={recordWebcam.status !== "RECORDING"}
                                    onClick={recordWebcam.stop}
                                    className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-10 h-10 button-primary"}
                                    type="button"
                                    > */}
                                    {/* Stop recording */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stop-fill w-4 h-4" viewBox="0 0 16 16">
                                        <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z"/>
                                    </svg>
                                </button>
                                <button
                                    disabled={recordWebcam.status !== "PREVIEW"}
                                    onClick={recordWebcam.retake}
                                    className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-10 h-10 button-primary"}
                                    type="button"
                                    > */}
                                    {/* Retake */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat w-4 h-4" viewBox="0 0 16 16">
                                        <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                        <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                    </svg>
                                </button>
                                <button
                                    disabled={recordWebcam.status !== "PREVIEW"}
                                    onClick={recordWebcam.download}
                                    className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-10 h-10 button-primary"}
                                    type="button"
                                    > */}
                                    {/* Download */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-down-fill h-4 w-4" viewBox="0 0 16 16">
                                        <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z"/>
                                    </svg>
                                </button>
                                <button
                                    disabled={recordWebcam.status !== "PREVIEW"}
                                    onClick={getRecordingFile}
                                    className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-10 h-10 button-primary"}
                                    type="button"
                                > */}
                                    {/* Get recording */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-up-fill" viewBox="0 0 16 16">
                                        <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z"/>
                                    </svg>
                                </button>

                            </div>
                    </div>
                        ) : (
                            <button disabled={
                                disabled ||
                                recordWebcam.status === "OPEN" ||
                                recordWebcam.status === "RECORDING" ||
                                recordWebcam.status === "PREVIEW" }
                                onClick={() => { setCameraRecord(true); recordWebcam.open();}} type="button" className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-12 h-12 button-primary"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-video-fill" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"/>
                            </svg>
                            </button>
                        )
                    }
                </div> */}

                    {
                        (showCamera === true) ? (
                        <div className="container-camera">
                            <div className="flex justify-end items-center">
                                <div onClick={() => setCamera(false)} className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-10 h-10 button-primary"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg w-6 h-6" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </div>
                            </div>
                        {imgSrc ? (
                            <img className="bg-white" src={imgSrc} alt="webcam" />
                        ) : (
                            <Webcam ref={webcamRef} />
                        )}
                        <div className="btn-container mx-auto flex justify-center items-center">
                            {/* <button className="h-8 w-full button-primary" onClick={capture}>Capture photo</button> */}
                            <button onClick={capture} type="button" className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-12 h-12 button-primary"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera w-6 h-6" viewBox="0 0 16 16">
                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                </svg>
                            </button>
                        </div>
                        </div>) : (
                            <button disabled={disabled} onClick={() => setCamera(true)} type="button" className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-12 h-12 button-primary"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera w-6 h-6" viewBox="0 0 16 16">
                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                </svg>
                            </button>
                        )
                    }


                    <label className={"shadow-main cursor-pointer flex justify-center items-center my-2 mr-2 p-3 rounded-full text-white uppercase font font-semibold w-12 h-12 button-primary"}>
                        <input disabled={disabled} onChange={(e)=>{
                            e.preventDefault()  
                            sendFile(message,targetUserId,user.username,conversation_id,e)
                        }} className="hidden" type="file" name="file" accept="image/png, image/jpeg, image/jpg, application/pdf, .docx,.ppt, .pptx, .xlsx,.xls" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip w-6 h-6" viewBox="0 0 16 16">
                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                        </svg>
                    </label>

                    <textarea placeholder="Type a message here" className="border-b w-11/12 px-2 bg-white outline-none"  onChange={e=>setMessage(e.target.value)} value = {message} disabled={disabled}/>
                    <button disabled={disabled || !message} type="submit" className="shadow-main button-primary flex justify-center items-center my-2 ml-2 p-3 rounded-full text-white uppercase font font-semibold w-12 h-12" >
                        <svg style={{transform: 'rotate(90deg)'}} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    )
}